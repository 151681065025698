.App-header {
  background-color: #043d35;
  min-height:fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content:space-around;
  align-content: center;
  font-size:30px;
  color: white;
  padding: 1rem;
  font-family: "Alloy-Regular";
  position: relative;
  top: 0;
  left: 0;
  
}
.Menu-title{
  font-family: "Alloy-Regular";
  font-weight: 100px;
  font-size: 55px;
  color: #DDF109;
  text-shadow:rgb(0, 0, 0) 3px 3px;
}
.img1 {
  position: relative;
  
  top: 0;
  left: 0;
  border: 1px solid #000000;
}

.img2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px solid #000000;
}