.App-footer {
    background-color:#043d35;
    height: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content:space-around;
    align-content: center;
    font-size:19px;
    color: #DDF109;
    padding: 1rem;
    font-family: "Alloy-Regular";
    text-shadow: rgb(27, 33, 22) 3px 3px;
    
  }